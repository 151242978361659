var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-overlay',{attrs:{"absolute":"","value":_vm.retriedTasksLoading,"color":'#ffffff'}},[_c('v-progress-circular',{attrs:{"size":150,"width":3,"color":"primary","indeterminate":""}})],1),(!_vm.retriedTasksLoading && _vm.retriedTasks.length > 0)?_c('v-card',{staticClass:"mx-4 mb-4 TestBlock__response",attrs:{"outlined":"","rounded":"lg"}},[_c('v-expansion-panels',{attrs:{"accordion":"","flat":"","multiple":""}},_vm._l((_vm.retriedTasks),function(action,index){return _c('v-expansion-panel',{key:index,on:{"click":function($event){_vm.selectedTask = action.id;
          _vm.getSubTasks(action);}}},[_c('v-expansion-panel-header',{staticClass:"px-4",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"text-right"},[_c('div',_vm._l(([
                    ...new Set(action.oldInvocations.map((inv) => inv.mode)),
                  ]),function(mode,index){return _c('v-chip',{key:index},[_vm._v(" "+_vm._s(mode)+" ")])}),1),_c('span',{staticClass:"text-caption grey--text"},[_vm._v(_vm._s(_vm.formatDuration(action.start, action.finish)))])])]},proxy:true}],null,true)},[_c('div',{staticClass:"d-flex"},[_c('workflow-status-icon',{staticClass:"mr-1",attrs:{"status":action.status}}),_c('div',[_c('div',[_vm._v(_vm._s(action.action_name))]),_c('div',{staticClass:"mt-2 mb-2"},[_vm._v("Task # "+_vm._s(action.id))]),_c('div',[_vm._v("Iteration № "+_vm._s(action.progress))])])],1)]),_c('v-expansion-panel-content',[_c('div',[_c('v-card',{staticClass:"mt-1 mb-2 p-1"},[_c('h3',{staticClass:"pt-3 pl-3 pb-2"},[_vm._v("Old invocations")]),_vm._l((action.oldInvocations),function(oldTask,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',{staticClass:"px-4"},[_vm._v(" Old invocation # "+_vm._s(index + 1)+" ")]),_c('v-expansion-panel-content',[_c('div',[_c('input-output-viewer',{attrs:{"action":{
                        ...oldTask,
                        input: oldTask.oldInput,
                        output: oldTask.oldOutput,
                      }}})],1)])],1)})],2),_c('input-output-viewer',{attrs:{"action":action}}),_c('v-card',{staticClass:"mt-4 mb-2 p-1"},[_c('h3',{staticClass:"pt-3 pl-3 pb-2"},[_vm._v("Scope tasks")]),_vm._l((_vm.scopeActionTaskMap[action.id]),function(action,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',{staticClass:"px-4"},[_c('div',[_vm._v(" "+_vm._s(_vm.findActionDesctiption(action.action_uuid).name)+" ")])]),_c('v-expansion-panel-content',_vm._l((action.tasks),function(task,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',{staticClass:"px-4"},[_c('div',[_c('workflow-status-icon',{staticClass:"mr-1",attrs:{"status":task.status}}),_c('div',[_vm._v("Task # "+_vm._s(task.id))]),_c('div',[_vm._v("Iteration № "+_vm._s(task.progress))])],1)]),_c('v-expansion-panel-content',[_c('div',[_c('input-output-viewer',{attrs:{"action":task}})],1)])],1)}),1)],1)})],2)],1)])],1)}),1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }