<template>
  <div class="TryCatchNode__container">
    <h4>Try catch</h4>
    <div class="TryCatchNode__input" :label="tryText" outlined dense :hide-details="true" />
  </div>
</template>

<script>
export default {
  name: 'LoopNode',
  props: ['connector', 'listIndex', 'storage_id'],
  computed: {
    tryText() {
      return `Try catch text retryAttempt)`;
    },
  },
  methods: {
    onUpdate() {
      const data = {
        connector: this.connector,
        listIndex: this.listIndex - 1,
      };
      this.$emit('update', data);
    },
  }
};
</script>

<style lang="scss" scoped>
@import './try-catch-node.scss';
</style>