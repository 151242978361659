<template>
  <v-row no-gutters class="HistoryNode__storage_details">
    <v-col
      class="HistoryNode__connector-list"
      :class="{ HistoryNode__expand_storage_details: !selectedConnector }"
      sm="12"
      lg="9"
    >
      <div
        class="HistoryNode__item-detail py-6 px-4"
        v-if="selectedWorkflowHistory"
      >
        <div
          class="pt-3 HistoryNode__connector-list-container"
          v-if="!historyConnectorListLoading"
        >
          <div class="d-flex">
            <div
              v-for="(connectors, listIndex) in connectorsList"
              :key="listIndex"
            >
              <cond-node
                v-if="isConditionalNode(connectorsListRef[listIndex - 1])"
                :connector="connectorsListRef[listIndex - 1]"
                :listIndex="listIndex"
                :storage_id="selectedWorkflowHistory.id"
                @update="changeCondBranch"
              />

              <loop-node
                v-if="isLoopNode(connectorsListRef[listIndex - 1])"
                :connector="connectorsListRef[listIndex - 1]"
                :listIndex="listIndex"
                :storage_id="selectedWorkflowHistory.id"
                @update="changeLoopCount"
              />

              <try-catch-node
                v-if="isTryCatchNode(connectorsListRef[listIndex - 1])"
                :connector="connectorsListRef[listIndex - 1]"
                :listIndex="listIndex"
                :storage_id="selectedWorkflowHistory.id"
                @update="changeTryCatch"
              />

              <div class="d-flex">
                <div>
                  <general-node
                    class="mr-5 mb-6"
                    v-for="(connector, index) in connectors"
                    @click.native="handleNodeClick(connector, listIndex)"
                    :key="connector.action_uuid"
                    :node="connector"
                    :index="index"
                  />
                </div>
                <v-divider
                  v-if="connectorsList[listIndex + 1]"
                  class="mx-4"
                  vertical
                ></v-divider>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-6 pt-3" v-else>
          <div
            v-for="skeletonIndex in 5"
            class="HistoryNode__node-connector-skeleton mr-5 mb-6 py-2"
            :key="skeletonIndex"
          >
            <v-skeleton-loader type="list-item-avatar, list-item" />
          </div>
        </div>
      </div>
      <div
        class="HistoryNode__item-empty-state d-flex flex-column align-center justify-center"
        v-if="!selectedWorkflowHistory && !historyLoading"
      >
        <p class="text-h3 blue-grey--text text--lighten-4">
          Select Item from the left
        </p>
      </div>
    </v-col>

    <v-col
      sm="12"
      lg="3"
      v-if="selectedConnector"
      class="HistoryNode__connector-details col-12 col-sm-6 col-md-4"
      :class="{ 'HistoryNode__hide_connector-details': !selectedConnector }"
    >
      <div
        class="HistoryNode__connector-details-header pa-4 d-flex align-start"
      >
        <ActionIcon :action="selectedConnector" />
        <div class="ml-2">
          <p class="text-h6 ma-0">
            {{ selectedConnector.action_name }}
          </p>
          <p class="text-body-6 ma-0 text-capitalize">
            {{ formatNodeType(selectedConnector) }}
          </p>
        </div>
      </div>

      <div class="HistoryNode__connector-details-content pa-4">
        <div
          v-if="connectorDetailsLoading"
          class="HistoryNode__node-connector-skeleton mr-5 mb-6 py-2"
        >
          <v-skeleton-loader type="list-item-avatar, list-item" />
        </div>
        <IOViewer v-else :action="selectedConnectorTaskDetails" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import GeneralNode from '@/components/node-types/general-node/general-node.vue';
import IOViewer from '@/components/io-viewer/io-viewer.vue';
import ActionIcon from '@/components/action-icon/action-icon.vue';
import { sortNodesByHeadAndTail } from '@/util/workflowDetails';
import LoopNode from './loop-node/loop-node.vue';
import TryCatchNode from './try-catch-node/try-catch-node.vue';
import CondNode from './cond-node/cond-node.vue';

const {
  mapGetters: workflowHistoryGetters,
  mapActions: workflowHistoryActions,
} = createNamespacedHelpers('workflows/history');

export default {
  name: 'HistoryNode',
  components: {
    GeneralNode,
    IOViewer,
    ActionIcon,
    LoopNode,
    CondNode,
    TryCatchNode,
  },
  props: ['selectedItem', 'selectedWorkflowHistory'],
  data() {
    return {
      selectedConnector: null,
      selectedConnectorTasksIds: [],
      selectedConnectorId: null,
      connectorsListRef: [],
      connectorsList: [],
      loopIteration: 0,
    };
  },
  computed: {
    ...workflowHistoryGetters({
      historyConnectorList: 'AUTOMATION_WORKFLOW_HISTORY_CONNECTOR_LIST',
      connectorTaskDetails: 'CONNECTOR_DETAILS',
      historyLoading: 'WORKFLOW_HISTORY_LOADING',
      historyConnectorListLoading:
        'FETCH_WORKFLOW_HISTORY_CONNECTOR_LIST_LOADING',
      connectorDetailsLoading: 'CONNECTOR_DETAILS_LOADING',
    }),
    selectedConnectorTaskDetails() {
      if (this.selectedConnectorId) {
        const output = this.connectorTaskDetails(this.selectedConnectorId);
        return {
          ...output,
          id: output.taskId,
          action_settings: this.selectedConnector.settings,
        };
      }

      return {};
    },
  },
  watch: {
    selectedConnectorId(connectorId) {
      if (connectorId) {
        this.getConnectorInputOutput({
          action_uuid: connectorId,
          storage_id: this.selectedWorkflowHistory.id,
          iteration: this.loopIteration,
        });
      }
    },
    selectedWorkflowHistory(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedConnector = null;
        this.selectedConnectorTasksIds = [];
        this.selectedConnectorId = null;
        this.connectorsListRef = [];
        this.connectorsList = [];
      }
      this.resetConnectorList();
    },
    historyConnectorListLoading() {
      this.resetConnectorList();
    },
  },
  methods: {
    ...workflowHistoryActions(['getConnectorInputOutput']),
    handleNodeClick(connector, listIndex, branch = 'true') {
      this.selectedConnector = connector;
      if (listIndex < this.connectorsList.length - 1) {
        while (listIndex < this.connectorsList.length - 1) {
          this.connectorsListRef.pop();
          this.connectorsList.pop();
          if (!this.connectorsListRef.find((r) => r.action_type === 'loop')) {
            this.loopIteration = 0;
          }
        }
      }

      if (connector.action_type === 'loop') {
        this.setLoopChain(connector);
        this.loopIteration = 1;
      }

      if (connector.action_type === 'conditional') {
        this.setCondChain(connector, branch);
      }

      if (connector.action_type === 'try_catch') {
        this.setTryCatchChain(connector);
      }

      this.selectedConnectorId = connector.id;
    },
    formatNodeType(value = '') {
      return (
        value.display_name || (value.action_type || '').split('_').join(' ') // fallback for existing workflows/connectors
      );
    },
    resetConnectorList() {
      if (this.selectedWorkflowHistory) {
        const list = this.historyConnectorList(this.selectedWorkflowHistory.id);
        this.connectorsList = [sortNodesByHeadAndTail(list)];
      } else {
        this.connectorsList = [];
      }
    },
    setLoopChain(connector) {
      this.connectorsListRef.push(connector);
      const list = connector.settings.cycleBody;
      this.connectorsList.push(sortNodesByHeadAndTail(list));
    },
    setCondChain(connector, branch) {
      this.connectorsListRef.push(connector);
      const list = connector.settings[branch];
      this.connectorsList.push(sortNodesByHeadAndTail(list));
    },
    setTryCatchChain(connector) {
      this.connectorsListRef.push(connector);
      const list = connector.settings.tryCatchBody;
      this.connectorsList.push(sortNodesByHeadAndTail(list));
    },
    isConditionalNode(connector) {
      return connector?.action_type === 'conditional';
    },
    isLoopNode(connector) {
      return connector?.action_type === 'loop';
    },
    isTryCatchNode(connector) {
      return connector?.action_type === 'try_catch';
    },
    changeLoopCount(data) {
      const { connector, listIndex, iteration } = data;
      this.handleNodeClick(connector, listIndex);
      this.loopIteration = iteration;
    },
    changeCondBranch(data) {
      const { connector, listIndex, branch } = data;
      this.handleNodeClick(connector, listIndex, branch);
    },
    changeTryCatch(data) {
      const { connector, listIndex } = data;
      this.handleNodeClick(connector, listIndex);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './history-node.scss';
</style>
