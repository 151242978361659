<template>
  <div class="CondNode__container">
    <h4>Conditional</h4>
    <v-switch
      v-model="branch"
      @change="onUpdate"
      :label="`Branch: ${branch ? 'YES' : 'NO'}`"
    ></v-switch>
  </div>
</template>

<script>
export default {
  name: 'CondNode',
  props: ['connector', 'listIndex'],
  data() {
    return {
      branch: true,
    };
  },
  methods: {
    onUpdate(branch) {
      const data = {
        connector: this.connector,
        listIndex: this.listIndex - 1,
        branch: branch.toString(),
      };
      this.$emit('update', data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './cond-node.scss';
</style>
