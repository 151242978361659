<template>
  <div>
      <v-row class="ml-1">
        <v-col>
          <v-text-field
              v-on:input="debounceInput"
              outlined
              dense
              v-model="search"
              name="Search"
              label="Search" />
        </v-col>
        <v-col>
          <div v-if="iteration">
            Iteration #: {{ iteration }}
            <v-btn icon @click.stop="selectIteration(null)" class="cancel-btn">
              <v-icon color="black"> mdi-close </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    <v-overlay absolute :value="failedTasksLoading" :color="'#ffffff'">
      <v-progress-circular :size="150" :width="3" color="primary" indeterminate />
    </v-overlay>
    <v-card outlined class="mx-4 mb-4 TestBlock__response" rounded="lg"
      v-if="!failedTasksLoading && failedTasks.length > 0">
      <v-expansion-panels accordion flat multiple>
        <v-expansion-panel v-for="(action, index) in failedTasks" :key="index">
          <v-expansion-panel-header class="px-4">
            <div class="d-flex">
              <workflow-status-icon :status="action.status" class="mr-1" />
              <div>
                <div>{{ action.action_name }}</div>
                <div class="mt-2 mb-2">Task # {{ action.id }}</div>
                <div>Iteration № <a @click.stop="() => selectIteration(action.progress)">{{ action.progress }}</a></div>
              </div>
            </div>
            <template v-slot:actions>
              <div>
                <span class="text-caption grey--text">{{
                  formatDuration(action.start, action.finish)
                }}</span>
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="retry-result"
              v-if="action.action_settings?.tryCatchBody && action.action_settings.tryCatchBody.length > 0">
              <p>Can't retry try catch action, retry failed action</p>
            </div>
            <div v-else>
              <div class="retry-result" v-if="retryResults[action.id]">
                <p>Success: {{ retryResults[action.id]?.success }}</p>
                <p>Message: {{ retryResults[action.id]?.message }}</p>
              </div>
              <div v-if="!retryResults[action.id] ||
                (retryResults[action.id] && !retryResults[action.id]?.success)
                ">
                <v-progress-circular v-if="retryResults[action.id]?.loading" :size="30" :width="3" color="primary"
                  indeterminate />
                <div v-else>
                  <v-btn color="primary" class="my-4" outlined @click="showDialogToggle(action)">
                    Provide new input
                  </v-btn>
                  <v-btn color="primary" class="ml-2" @click="retrySingleActionClick(action)">
                    Retry single action
                  </v-btn>
                  <v-btn color="primary" class="ml-2" @click="retryScopeClick(action)">
                    Retry scope
                  </v-btn>
                </div>
              </div>

              <div>
                <div v-if="newInputs[action.id]">
                  <div class="d-flex justify-content-between">
                    <div>New Input:</div>
                    <v-btn text color="primary" small outlined @click="clearNewInput(action)">
                      Clear
                    </v-btn>
                  </div>

                  <json-viewer :value="newInputs[action.id]" :expand-depth="0" boxed sort copyable></json-viewer>
                </div>
                <input-output-viewer :action="action" />
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <v-btn
        v-if="!failedTasksLoading && failedTasks.length > 0"
        @click.stop="loadMoreFailedTasks()"
        color="primary"
        outlined
        small
        class="ml-4 mb-4"
        :disabled="allLoaded"
    >
      Load More
    </v-btn>
    <v-dialog v-model="showDialog" width="700">
      <v-card>
        <v-card-title class="InputSettings__modal-title">
          JavaScript code editor
        </v-card-title>

        <v-card-text class="InputSettings__modal-content">
          <CodeEditor v-if="showDialog" :value="newInputScript" @input="(val) => onUpdate(val)" language="javascript"
            class="mb-3" :runScript="testScript" :placeholder="placeholder" :suggestions="availableInputFields" />
          <p v-if="error" class="error--text">
            {{ error }}
          </p>
          <div v-if="output">
            <div v-if="typeof output === 'object' || Array.isArray(output)">
              <div class="output-title">Output:</div>
              <div class="output-tree">
                <v-treeview dense open-on-click :items="output">
                  <template v-slot:label="{ item }">
                    <div v-if="item.image">
                      <button @click="openImageModal(item.image)">
                        {{ item.name }}
                      </button>
                    </div>
                    <div v-else>
                      <div>{{ item.name }}</div>
                    </div>
                  </template>
                </v-treeview>
              </div>
            </div>
            <div v-else>Output: {{ output }}</div>
          </div>
        </v-card-text>

        <v-divider />

        <v-card-actions class="justify-end py-3 px-4">
          <v-btn color="primary" text @click="showDialog = false">Done</v-btn>
          <v-btn color="primary" :loading="testingScript" @click="testScript()">
            Test Script
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import InputOutputViewer from '@/components/io-viewer/io-viewer.vue';
import * as moment from 'moment';
import WorkflowStatusIcon from '@/components/workflow-status-icon/workflow-status-icon.vue';
import CodeEditor from '@/components/code-editor/code-editor.vue';
import { getOutputTree } from '@/util/actionsModels';
import JsonViewer from 'vue-json-viewer';
import debounce from "lodash/debounce";

const {
  mapGetters: workflowHistoryGetters,
  mapActions: workflowHistoryActions,
} = createNamespacedHelpers('workflows/history');

const { mapActions: workflowDetailsActions, mapActions: workflowsActions } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'FailedHistoryList',
  components: {
    InputOutputViewer,
    WorkflowStatusIcon,
    CodeEditor,
    JsonViewer,
  },
  data() {
    return {
      page: 0,
      iteration: undefined,
      allLoaded: false,
      selectedTasks: [],
      selectedTask: null,
      newInputScripts: {},
      newInputs: {},
      retryResults: {},
      showDialog: false,
      testingScript: false,
      availableInputFields: [],
      placeholder: `
        (
          () => {
            return {
              field1: 'X1',
              field2: 'X2',
              field3: 'x3'
            }
          }
        )()
      `,
      error: '',
      output: '',
    };
  },
  created() {
    this.refresh();
    this.loadFailedTasks();
  },
  props: ['selectedWorkflowHistory'],
  computed: {
    ...workflowHistoryGetters({
      failedTasks: 'FAILED_TASKS',
      failedTasksLoading: 'FAILED_TASKS_LOADING',
    }),
    newInputScript() {
      return this.newInputScripts[this.selectedTask];
    },
  },
  watch: {
    selectedWorkflowHistory() {
      this.refresh();
      this.loadFailedTasks();
    },
    showDialog(newVal) {
      if (newVal === false) {
        this.selectedTask = null;
      }
    },
  },
  methods: {
    ...workflowHistoryActions(['getFailedTasks']),
    ...workflowDetailsActions(['retryActions']),
    ...workflowsActions(['executeSingleAction']),
    debounceInput: debounce(function (e) {
      this.search = e;
      this.loadFailedTasks()
    }, 1000),
    getActionInput(action) {
      return this.newInputs[action.id] ?? action.input;
    },
    refresh() {
      this.selectedTask = null;
      this.newInputScripts = {};
      this.newInputs = {};
      this.retryResults = {};
      this.output = '';
      this.error = '';
    },
    selectIteration(iteration) {
      this.iteration = iteration;
      this.loadFailedTasks();
    },
    loadFailedTasks() {
      const storageId = this.selectedWorkflowHistory.id;
      this.page = 0;
      this.allLoaded = false;
      this.getFailedTasks({ storageId, page: this.page, push: false, search: this.search, iteration: this.iteration });
    },
    async loadMoreFailedTasks() {
      const storageId = this.selectedWorkflowHistory.id;
      this.page += 1;
      const preLoadCount = this.failedTasks.length;
      await this.getFailedTasks({ storageId, page: this.page, push: true });
      const postLoadCount = this.failedTasks.length;
      if(postLoadCount === preLoadCount) {
        this.allLoaded = true;
      }
    },
    onUpdate(newScript) {
      this.newInputScripts = {
        ...this.newInputScripts,
        [this.selectedTask]: newScript,
      };
    },
    async retrySingleActionClick(task) {
      try {
        this.retryResults = {
          ...this.retryResults,
          [task.id]: { loading: true },
        };
        const result = await this.retryActions({
          storage_id: task.parent_storage_id,
          task_id: task.id,
          input: this.newInputs[task.id],
          mode: 'single',
        });
        this.retryResults = {
          ...this.retryResults,
          [task.id]: {
            message: 'Results can be found on RETRIED tab',
            ...result,
            loading: false,
          },
        };
      } catch (e) {
        console.error(e);
      }
    },
    async retryScopeClick(task) {
      try {
        this.retryResults = {
          ...this.retryResults,
          [task.id]: { loading: true },
        };
        const result = await this.retryActions({
          storage_id: task.parent_storage_id,
          task_id: task.id,
          input: this.newInputs[task.id],
          mode: 'scope',
        });
        this.retryResults = {
          ...this.retryResults,
          [task.id]: {
            message: 'Results can be found on RETRIED tab',
            ...result,
            loading: false,
          },
        };
      } catch (e) {
        console.error(e);
      }
    },
    clearNewInput(action) {
      this.newInputs = {
        ...this.newInputs,
        [action.id]: null,
      };
      this.newInputScripts = {
        ...this.newInputScripts,
        [action.id]: null,
      };
    },
    formatDuration(start, finish) {
      const difference = moment(finish).diff(moment(start), 'milliseconds');
      return moment.utc(difference).format('m [min] s [sec]');
    },
    showDialogToggle(action) {
      this.output = '';
      this.error = '';
      this.selectedTask = action.id;
      const newInputScript = `(
  () => {
      return ${JSON.stringify(action.input)}
    }
  )()`;
      if (!this.newInputScripts[action.id]) {
        this.newInputScripts[action.id] = newInputScript;
      }

      this.showDialog = true;
    },
    async testScript() {
      try {
        this.error = '';
        this.output = '';
        this.testingScript = true;

        const options = {
          creds: null,
          data: {
            script: this.newInputScripts[this.selectedTask],
          },
          action: 'execute_javascript',
          params: [],
        };
        const data = await this.executeSingleAction(options);
        if (!data || !data.success) {
          throw Error(data && data.message);
        }
        const output = data.data ?? data.result ?? 'no output';
        if (typeof output === 'object' || Array.isArray(output)) {
          this.output = getOutputTree(output);
        } else {
          this.output = output;
        }
        this.newInputs[this.selectedTask] = output;
      } catch (e) {
        this.error = e.data ? e.data.message : e;
      } finally {
        this.testingScript = false;
      }
    },
  },
};
</script>

<style scoped>
.TestBlock {
  &__btn-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }

  &__run-status-title,
  &__response-title {
    border-bottom: 1px solid #dde2e5;
  }
}

.InputSettings {
  display: flex;
  flex-direction: column;
  align-items: start;

  &__modal-title {
    background: #f8f9fa;
    border-bottom: thin solid #dde2e5;
  }

  &__modal-content {
    padding-top: 20px !important;
  }
}

.retry-result {
  border: 1px solid #dde2e5;
  padding: 5px;
}

.cancel-btn {
  max-width: 50px;
  max-height: 50px;
  margin-bottom: 5px;
}
</style>
