<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <span class="icon-spacing">
        <img
          v-if="action.svgIcon"
          :src="`${publicPath}/icons/${action.icon}.svg`"
          alt=""
          height="20"
          style="vertical-align: middle"
          v-on="on"
          v-bind="attrs"
        />
        <v-icon
          v-else
          text
          :color="actionColor"
          v-bind="attrs"
          v-on="on"
        >
          {{ action.icon }}
        </v-icon>
      </span>
    </template>
    <span>{{ action.name }}</span>
  </v-tooltip>
</template>

<script>
import { getActionColor } from "@/util/action-types";
import constants from "@/util/constants";

export default {
  name: "ActionIcon",
  data() {
    return {
      publicPath: constants.isDevelopment ? "" : "",
    };
  },
  computed: {
    actionColor() {
      return (
        this.color ||
        this.action.color ||
        getActionColor(this.action.icon)
      );
    },
  },
  props: {
    action: {
      required: true,
    },
    color: {
      required: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-spacing {
  padding: 3px;
}
</style>
