<template>
  <div
    class="GeneralNode"
    @click="selectNode"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    :style="borderStyle"
  >
    <div class="GeneralNode__header d-flex align-center">
      <div class="GeneralNode__header-icon">
        <ActionIcon :action="node" />
      </div>
      <div class="GeneralNode__header-title">
        {{ nodeType || 'Choose action' }}
      </div>
    </div>
    <div class="GeneralNode__content">
      {{ node.name }}
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ActionIcon from '@/components/action-icon/action-icon.vue';

const { mapMutations: workflowDetailsMutations } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'GeneralNode',
  components: {
    ActionIcon,
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    parentNodeList: {
      type: Array,
      default: () => [],
    },
    parentNode: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      borderStyle: {
        borderColor: 'transparent',
      },
    };
  },
  computed: {
    iconColor() {
      return this.node && this.node.color ? this.node.color : '#000';
    },
    nodeType() {
      return (
        this.node.display_name ||
        (this.node.action_type || '').split('_').join(' ') // fallback for existing workflows/connectors
      );
    },
  },
  methods: {
    ...workflowDetailsMutations({
      setSelectedNode: 'SET_SELECTED_NODE',
      setSelectedNodeOption: 'SET_SELECTED_NODE_OPTIONS',
    }),
    selectNode() {
      const options = {
        index: this.index,
        parentNode: this.parentNode,
        parentNodeList: this.parentNodeList,
      };
      this.setSelectedNode(this.node);
      this.setSelectedNodeOption(options);
    },
    handleMouseEnter() {
      this.borderStyle.borderColor = this.iconColor;
    },
    handleMouseLeave() {
      this.borderStyle.borderColor = 'transparent';
    },
  },
};
</script>

<style scoped lang="scss">
@import './general-node.scss';
</style>
