<template>
  <div class="LoopNode__container">
    <h4>Loop</h4>
    <v-text-field
      class="LoopNode__input"
      :label="loopText"
      outlined
      dense
      :hide-details="true"
      :value="iteration"
      @input="onInputChange"
    />
  </div>
</template>

<script>
export default {
  name: 'LoopNode',
  props: ['connector', 'listIndex', 'storage_id'],
  data() {
    return {
      iteration: 1,
      maxCount: 1,
    };
  },
  mounted() {
    this.getLoopCount();
  },
  computed: {
    loopText() {
      return `Loop Iteration(${this.iteration}/${this.maxCount})`;
    },
  },
  methods: {
    onUpdate() {
      const data = {
        connector: this.connector,
        listIndex: this.listIndex - 1,
        iteration: this.iteration,
      };
      this.$emit('update', data);
    },
    onInputChange(value) {
      if (Number.isNaN(Number(value))) {
        return;
      }

      if (Number(value) > this.maxCount) {
        this.iteration = this.maxCount;
      } else if (Number(value) < 1) {
        this.iteration = 1;
      } else {
        this.iteration = Number(value);
      }
      this.onUpdate();
    },
    async getLoopCount() {
      try {
        const getters = this.$store.getters;
        const customer = getters['customer/CUSTOMER'];
        const selected_customer = getters['customer/SELECTED_CUSTOMER'];
        let url = '/proxy/connector-input-output';

        let customer_id = customer.customer_id;
        if (customer_id === 0) {
          customer_id = selected_customer.customer_id;
        }
        const data = {
          customer_id,
          storage_id: this.storage_id,
          action_uuid: this.connector.id,
        };
        const response = await window.axios.post(url, data);
        if (response && response.data.success) {
          this.maxCount = response.data.connector_details?.input?.length;
        } else {
          throw response.data;
        }
      } catch {
        console.error('Error getting loop count.');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './loop-node.scss';
</style>
