<template>
  <v-icon
    :color="iconInfo.color"
  >
    {{iconInfo.icon}}
  </v-icon>
</template>

<script>
import { workflowStatus } from "@/util/workflow-statuses";

export default {
  name: "WorkflowStatusIcon",
  props: {
    status: { type: String, default: workflowStatus.PENDING, required: true },
  },
  computed: {
    iconInfo() {
      switch (this.status) {
        case workflowStatus.SUCCESS:
          return { icon: "mdi-check-circle-outline", color: "success" };
        case workflowStatus.FAILURE:
          return { icon: "mdi-close-circle-outline", color: "error" };
        case workflowStatus.PAUSED:
          return { icon: "mdi-play-circle-outline", color: "orange" };
        case workflowStatus.CANCELLED:
          return { icon: "mdi-close-circle-outline", color: "orange" };
        default:
          return { icon: "mdi-progress-clock", color: "primary" };
      }
    }
  },
};
</script>

<style scoped>
</style>
