<template>
  <div>
    <v-overlay
      absolute
      :value="retriedTasksLoading"
      :color="'#ffffff'">
      <v-progress-circular
        :size="150"
        :width="3"
        color="primary"
        indeterminate />
    </v-overlay>
    <v-card
      outlined
      class="mx-4 mb-4 TestBlock__response"
      rounded="lg"
      v-if="!retriedTasksLoading && retriedTasks.length > 0">
      <v-expansion-panels
        accordion
        flat
        multiple>
        <v-expansion-panel
          v-for="(action, index) in retriedTasks"
          :key="index"
          @click="
            selectedTask = action.id;
            getSubTasks(action);
          ">
          <v-expansion-panel-header class="px-4">
            <div class="d-flex">
              <workflow-status-icon
                :status="action.status"
                class="mr-1" />
              <div>
                <div>{{ action.action_name }}</div>
                <div class="mt-2 mb-2">Task # {{ action.id }}</div>
                <div>Iteration № {{ action.progress }}</div>
              </div>
            </div>
            <template v-slot:actions>
              <div class="text-right">
                <div>
                  <v-chip
                    v-for="(mode, index) in [
                      ...new Set(action.oldInvocations.map((inv) => inv.mode)),
                    ]"
                    :key="index">
                    {{ mode }}
                  </v-chip>
                </div>

                <span class="text-caption grey--text">{{
                  formatDuration(action.start, action.finish)
                }}</span>
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <v-card class="mt-1 mb-2 p-1">
                <h3 class="pt-3 pl-3 pb-2">Old invocations</h3>
                <v-expansion-panel
                  v-for="(oldTask, index) in action.oldInvocations"
                  :key="index">
                  <v-expansion-panel-header class="px-4">
                    Old invocation # {{ index + 1 }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <input-output-viewer
                        :action="{
                          ...oldTask,
                          input: oldTask.oldInput,
                          output: oldTask.oldOutput,
                        }" />
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-card>

              <input-output-viewer :action="action" />

              <v-card class="mt-4 mb-2 p-1">
                <h3 class="pt-3 pl-3 pb-2">Scope tasks</h3>
                <v-expansion-panel
                  v-for="(action, index) in scopeActionTaskMap[action.id]"
                  :key="index">
                  <v-expansion-panel-header class="px-4">
                    <div>
                      {{ findActionDesctiption(action.action_uuid).name }}
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-expansion-panel
                      v-for="(task, index) in action.tasks"
                      :key="index">
                      <v-expansion-panel-header class="px-4">
                        <div>
                          <workflow-status-icon
                            :status="task.status"
                            class="mr-1" />
                          <div>Task # {{ task.id }}</div>
                          <div>Iteration № {{ task.progress }}</div>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div>
                          <input-output-viewer :action="task" />
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-card>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import InputOutputViewer from '@/components/io-viewer/io-viewer.vue';
import * as moment from 'moment';
import WorkflowStatusIcon from '@/components/workflow-status-icon/workflow-status-icon.vue';
import { getActionById } from '@/util/action-types';

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

const {
  mapGetters: workflowHistoryGetters,
  mapActions: workflowHistoryActions,
} = createNamespacedHelpers('workflows/history');

export default {
  name: 'RetriedHistoryList',
  components: {
    InputOutputViewer,
    WorkflowStatusIcon,
  },
  data() {
    return {
      selectedTask: null,
      scopeActionTaskMap: {},
      rootRetryTasks: [],
      testingScript: false,
      availableInputFields: [],
    };
  },
  created() {
    this.refresh();
    this.loadFailedTasks();
  },
  props: ['selectedWorkflowHistory'],
  computed: {
    ...workflowDetailsGetters({
      workflow: 'WORKFLOW',
    }),
    ...workflowHistoryGetters({
      retriedTasks: 'RETRIED_TASKS',
      retriedTasksLoading: 'RETRIED_TASKS_LOADING',
    }),
    actions() {
      if (!Array.isArray(this.workflow.actions)) {
        return Object.values(this.workflow.actions);
      }
      return this.workflow.actions;
    },
  },
  watch: {
    selectedWorkflowHistory() {
      this.refresh();
      this.loadFailedTasks();
    },
  },
  methods: {
    ...workflowHistoryActions(['getRetriedTasks', 'getScopeRetriedTasks']),
    refresh() {
      this.selectedTask = null;
    },
    findActionDesctiption(actionId) {
      const action = getActionById(this.actions, actionId);
      return action;
    },
    async getSubTasks(task) {
      if (!task.oldInvocations.some((inv) => inv.mode === 'scope')) {
        return;
      }
      if (this.scopeActionTaskMap[task.id]) {
        return;
      }
      const actionTaskMap = await this.getScopeRetriedTasks({
        storageId: this.selectedWorkflowHistory.id,
        taskId: task.id,
      });
      this.scopeActionTaskMap = {
        ...this.scopeActionTaskMap,
        [task.id]: Object.keys(actionTaskMap).map((key) => {
          return {
            action_uuid: key,
            tasks: actionTaskMap[key],
          };
        }),
      };
    },
    loadFailedTasks() {
      const storageId = this.selectedWorkflowHistory.id;
      this.getRetriedTasks({ storageId });
    },
    formatDuration(start, finish) {
      const difference = moment(finish).diff(moment(start), 'milliseconds');
      return moment.utc(difference).format('m [min] s [sec]');
    },
  },
};
</script>

<style scoped>
.TestBlock {
  &__btn-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }

  &__run-status-title,
  &__response-title {
    border-bottom: 1px solid #dde2e5;
  }
}

.InputSettings {
  display: flex;
  flex-direction: column;
  align-items: start;

  &__modal-title {
    background: #f8f9fa;
    border-bottom: thin solid #dde2e5;
  }

  &__modal-content {
    padding-top: 20px !important;
  }
}

.old-invocations {
  border: #dde2e5 thin solid;
}
</style>
